<template>
  <div>
    <vx-card actionable class="cardx" title="Delays(Eligibility)">
      <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <datepicker
            placeholder="Select Date"
            v-model="dateRange"
            style="z-index: 1000"
          ></datepicker>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <v-select
            v-if="getrole('cm4')"
            label="cities"
            :options="cities"
            v-model="selected_city"
            style="width: 240px; z-index: 1000"
          ></v-select>
          <v-select
            v-if="getrole('cm1') || getrole('ac1') || getrole('exed')"
            disabled
            label="cities"
            :options="cities"
            v-model="selected_city"
            style="width: 240px; z-index: 1000"
          ></v-select>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <v-select
            v-if="getrole('cm4')"
            label="City classification"
            :options="city_classification_list"
            v-model="selected_city_classification"
            style="width: 240px; z-index: 1000"
          ></v-select>
          <v-select
            v-if="getrole('cm1') || getrole('ac1') || getrole('exed')"
            disabled
            label="City classification"
            :options="city_classification_list"
            v-model="selected_city_classification"
            style="width: 240px; z-index: 1000"
          ></v-select>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <vs-button
            color="dark"
            type="filled"
            @click="getSpocsWithCityClasification()"
            >GO</vs-button
          >
        </vs-col>
      </vs-row>
      <vs-table
        :data="tableRows"
        style="
          margin-top: 3%;
          border-top: 1px solid;
          border-left: 1px solid;
          color: black;
        "
      >
        <template slot="thead">
          <vs-th
            :key="index"
            v-for="(head, index) in tableHeaders"
            :colspan="head.colspan"
            :style="{ borderRight: '1px solid', fontSize: '13px' }"
            >{{ head.header }}</vs-th
          >
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" :style="tr.style">
            <vs-td>{{ getName(tr.level) }}</vs-td>
            <vs-td style="border: 1px solid">
              <div
                @click="
                  preFlightFetch(
                    tr.level === '' ? tr.overall : tr.level,
                    selected_city,
                    'allocated',
                    selected_city_classification
                  )
                "
              >
                {{ tr.city.allocated }}
              </div>
            </vs-td>
            <vs-td style="border: 1px solid">
              <div
                @click="
                  preFlightFetch(
                    tr.level,
                    selected_city,
                    'delays',
                    selected_city_classification
                  )
                "
              >
                {{ tr.city.delays }}
              </div>
            </vs-td>
            <vs-td
              v-for="(spoc, spocIndex) in tr.dynamicSpoc"
              :key="spocIndex"
              :style="{
                border: '1px solid',
              }"
            >
              <div
                @click="
                  preFlightFetch(
                    tr.level,
                    [tr, spocIndex],
                    data[0].dynamicSpoc[spocIndex],
                    selected_city_classification
                  )
                "
              >
                {{ spoc }}
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";
import VueApexCharts from "vue-apexcharts";
import EventBus from "../components/eventbus";
export default {
  data() {
    return {
      team: "",
      city_classification_list: [],
      selected_city_classification: "",
      popupString: "",
      name: "App",
      type: "margin",
      fromDate: null,
      toDate: null,
      spoc_names: [],
      select1: "",
      select2: "",
      dateRange: new Date(),
      selected_city: "",
      loggedin: "",
      cities: [],
      tablerowConstant: [
        {
          style: {
            borderTop: "1px solid",
            borderBottom: "1px solid",
          },
          level: "",
        },
        {
          style: { borderTop: "1px solid", borderBottom: "1px solid" },
          level: "Check",
          type: "eligibility",
        },
        {
          style: { borderBottom: "1px solid" },
          level: "Clear",
          type: "eligibility",
        },
        {
          style: { borderBottom: "1px solid" },
          level: "Done",
          type: "eligibility",
        },
        {
          style: { borderBottom: "1px solid" },
          level: "Eligibility",
          type: "eligibility",
        },
        {
          style: { borderBottom: "1px solid" },
          level: "No",
          type: "eligibility",
        },
        {
          style: { borderBottom: "1px solid" },
          level: "Pending",
          type: "eligibility",
        },
        {
          style: { borderBottom: "1px solid" },
          level: "PGDA",
          type: "eligibility",
        },
        {
          style: { borderBottom: "1px solid" },
          level: "PGDPA",
          type: "eligibility",
        },
        {
          style: { borderBottom: "1px solid" },
          level: "Unclear",
          type: "eligibility",
        },
        {
          style: { borderBottom: "1px solid" },
          level: "YES",
          type: "eligibility",
        },
        {
          style: {
            borderTop: "1px solid",
            borderBottom: "1px solid",
          },
          level: "Total",
          type: "total",
        },
      ],
      tableHeaders: [],
      tableRows: [],
    };
  },
  mounted() {
    this.team = localStorage.getItem("team");
    this.getSpocCities();
    this.getSpocs();
    EventBus.$on("updateCoverage", () => {
      this.updateValues();
    });
  },
  watch: {
    selected_city(val) {
      this.getCityClasificationCodes(val);
    },
    selected_city_classification(val) {
      this.selected_city_classification = val;
    },
    dateRange() {
      this.updateValues();
    },
  },
  methods: {
    getSpocCities() {
      let url = `${constants.SERVER_API}getSpocCities`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getSpocCities response", response);
          this.cities = response.data;
          console.log("getSpocCities", this.cities);
          if (localStorage.getItem("role") === "cm4") {
            this.selected_city = this.cities[0];
          } else if (
            localStorage.getItem("role") === "cm1" ||
            localStorage.getItem("role") === "ac1" ||
            localStorage.getItem("role") === "exed"
          ) {
            this.spoc_names.forEach((spoc) => {
              if (spoc.id === this.loggedin) {
                console.log("assigning the city");
                this.selected_city = spoc.city;
              }
            });
          }
          // this.extractSpocByCity(this.cities);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getCityClasificationCodes(city) {
      let url = `${constants.SERVER_API}getCityClasificationCodes?city=${city}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getCityClasificationCodes", response);

          this.city_classification_list = this.sortArrayAlphabetically(
            response.data
          );
          if (localStorage.getItem("role") === "cm4") {
            this.selected_city_classification =
              this.city_classification_list[0];
          } else if (
            localStorage.getItem("role") === "cm1" ||
            localStorage.getItem("role") === "ac1" ||
            localStorage.getItem("role") === "exed"
          ) {
            this.spoc_names.forEach((spoc) => {
              if (spoc.id === this.loggedin) {
                console.log("assigning the city");
                this.selected_city_classification = spoc.city_classification;
              }
            });
          }
          console.log(
            this.city_classification_list,
            "city_classification_list"
          );
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSpocsWithCityClasification() {
      // let city = this.new_selected_city;
      console.log(this.selected_city);
      console.log(this.selected_city_classification);
      let url = `${constants.SERVER_API}getSpocsWithCityClasification?city=${this.selected_city}&city_clasification=${this.selected_city_classification}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getSpocsWithCityClasification", response);
          this.selected_spocs = response.data.spocs;
          console.log("step1", this.selected_spocs);

          // this.getSpocIds(spoc_data);
          this.updateValues();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    preFlightFetch(level, placement, type, selected_city_classification) {
      let city_classification = selected_city_classification;
      if (type === "Allocated" || type === "Delays") {
        let newtype = this.convertType(type);
        let spocname = this.mapSearchSpoc(placement);
        this.fetchSelectedData(level, spocname, newtype, city_classification);
      } else {
        this.fetchSelectedData(level, placement, type, city_classification);
      }
    },
    mapSearchSpoc(placement) {
      let obj = placement[0];
      let objindex = placement[1];
      let objlength = obj.dynamicSpoc.length / 2;
      let mapObj = [];
      for (let i = 1; i <= objlength; i++) {
        mapObj.push({
          sequence: i,
          data: [],
        });
      }
      let count = 0;
      let map = 0;
      let mapname = 0;
      obj.dynamicSpoc.forEach((spocstat, index) => {
        if (count === 2) {
          count = 0;
          map = map + 1;
        }
        mapObj[map].data.push(spocstat);
        if (index === objindex) {
          mapname = map;
        }
        count = count + 1;
      });
      let spoc = this.foundspoc(mapname);
      return spoc;
    },
    foundspoc(spocindex) {
      let spocarr = [];
      this.tableHeaders.forEach((header, index) => {
        if (index > 1) {
          spocarr.push(header);
        }
      });
      return spocarr[spocindex].header;
    },
    convertType(type) {
      let newtype = "";
      newtype = type.toLowerCase();
      return newtype;
    },
    getstyle(data) {
      if (
        data.overall.contacted === "Total" ||
        data.level === "M3" ||
        data.level === "L6" ||
        data.level === "L1" ||
        data.level === "M4" ||
        data.overall.contacted === "N/A"
      ) {
        return "1px solid";
      } else {
        return "";
      }
    },
    fetchSelectedData(level, placement, type, city_classification) {
      this.popupString = `${this.getName(type)} OF ${this.getName(
        level
      )}-${placement.toUpperCase()}`;
      this.selected_spocs.forEach((spoc) => {
        console.log("spoc", spoc);
        if (spoc.full_name === placement) {
          placement = spoc.id;
        }
      });
      if (this.dateRange === "") {
        this.$vs.notify({
          title: "Select a Date",
          text: "Select a date to continue using this feature",
          color: "warning",
        });
      } else {
        this.$vs.loading();
        let url = `${constants.SERVER_API}getDelaysByEligibilityContactDetails`;
        let obj = {
          selected_date: this.datatoTimestamp(this.dateRange),
          eligibility: level,
          type: type,
          spoc_id: "",
          city: "",
          city_classification: "",
        };
        if (Number.isInteger(placement)) {
          obj.spoc_id = placement;
        } else {
          obj.city = placement;
          obj.city_classification = city_classification;
        }
        if (obj.level === "Total") {
          obj.level = "total";
        }
        // console.log(obj);
        axios
          .post(url, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            response.data.data.forEach((lead) => {
              lead.engagements = [];
              lead.mobile = [];
              lead.email = [];
            });
            this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
            this.$vs.loading.close();
            EventBus.$emit("open-coverage-details", [
              obj,
              url,
              response.data,
              "getDelaysByEligibilityContactDetails",
              this.popupString,
              response.data.total,
            ]);
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },
    getSpocList(selected_spocs) {
      console.log("selected spocs list step 1", selected_spocs);
      let selected_spocs_list = [];
      selected_spocs.forEach((spoc) => {
        selected_spocs_list.push(spoc.id);
      });
      console.log("selected spocs list step 2", selected_spocs_list);
      selected_spocs_list = selected_spocs_list.join();
      return selected_spocs_list;
    },
    updateValues() {
      console.log(this.selected_spocs);
      if (this.selected_city === "") {
        this.$vs.notify({
          title: "Select a City",
          text: "Select a city to get coverage details",
          color: "warning",
        });
        return;
      }
      this.$vs.loading();
      let obj = {
        selected_date: this.datatoTimestamp(this.dateRange),
        spoc_ids: this.getSpocList(this.selected_spocs),
        team: "CM",
      };
      axios
        .get(`${constants.SERVER_API}getDelaysByEligibility`, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getDelaysByEligibility", response);
          if (response.data.status === "error") {
            this.handleNotification(response);
            this.$vs.loading.close();
          } else {
            this.generateDataTable(response.data.data);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getName(name) {
      let newname = name.replace(/_/g, " ");
      return newname.toUpperCase();
    },
    generateDataTable(response) {
      this.tableRows = this.tablerowConstant;
      this.generateTableHeaders(response);
      this.generateTableRows(response);
    },
    generateTableHeaders(response) {
      this.tableHeaders = [];
      this.tableHeaders.push({
        header: "Level",
        colspan: 1,
      });
      let city = this.selected_city;
      this.tableHeaders.push({
        header: city,
        colspan: 2,
      });
      response.forEach((cityData) => {
        let obj = {
          header: cityData.spoc_name,
          colspan: 2,
        };
        this.tableHeaders.push(obj);
      });
      console.log(this.tableHeaders);
    },
    generateTableRows(response) {
      this.tableRows[0].dynamicSpoc = [];
      this.tableRows[0].city = {
        allocated: "Allocated",
        delays: "Delays",
      };
      this.tableRows.forEach((row, index) => {
        if (index !== 0) {
          row.dynamicSpoc = [];
          row.city = {
            allocated: 0,
            delays: 0,
          };
        }
      });
      response.forEach(() => {
        let allocated = 0;
        let delays = 0;
        this.tableRows[0].dynamicSpoc.push("Allocated");
        this.tableRows[0].dynamicSpoc.push("Delays");
        this.tableRows.forEach((row, index) => {
          if (index !== 0) {
            row.dynamicSpoc.push(allocated);
            row.dynamicSpoc.push(delays);
          }
        });
      });
      // this.$vs.loading.close();
      this.InitialCalculation(response);
    },
    InitialCalculation(response) {
      this.tableRows.forEach((row, index) => {
        if (index > 0) {
          let cityallocated = 0;
          let citydelays = 0;
          row.dynamicSpoc = [];
          response.forEach((spoc) => {
            for (var key in spoc) {
              if (spoc.hasOwnProperty(key)) {
                if (key !== "NA" && key !== "spoc_id" && key !== "spoc_name") {
                  if (key === row.level) {
                    cityallocated = cityallocated + spoc[key].allocated;
                    citydelays = citydelays + spoc[key].delays;
                    row.dynamicSpoc.push(spoc[key].allocated);
                    row.dynamicSpoc.push(spoc[key].delays);
                  }
                }
              }
            }
          });
          row.city.allocated = cityallocated;
          row.city.delays = citydelays;
        }
      });
      let totalcityallocated = 0;
      let totalcitydelays = 0;
      let dynamicarr = [];
      this.tableRows.forEach((row, index) => {
        if (index > 0) {
          totalcityallocated = totalcityallocated + row.city.allocated;
          totalcitydelays = totalcitydelays + row.city.delays;
          row.dynamicSpoc.forEach((spocStat, index2) => {
            dynamicarr[index2] =
              parseInt(dynamicarr[index2]) + parseInt(spocStat);
          });
        }
      });
      // this.tableRows[1].city.allocated = totalcityallocated;
      // this.tableRows[1].city.delays = totalcitydelays;
      // this.tableRows[1].dynamicSpoc = dynamicarr;
      this.calculateTotals();
      this.$vs.loading.close();
    },
    calculateTotals() {
      console.log(this.tableRows);
      // let parts = ["P1", "P2", "P3"];
      // parts.forEach(eachPart => {
      let cityAllocated = 0;
      let cityDelays = 0;

      let dynamicSpocValues = [];
      this.tableRows[0].dynamicSpoc.forEach(() => {
        dynamicSpocValues.push(0);
      });
      this.tableRows.forEach((tableRow) => {
        if (tableRow.type === "eligibility") {
          cityAllocated =
            parseInt(cityAllocated) + parseInt(tableRow.city.allocated);
          cityDelays = parseInt(cityDelays) + parseInt(tableRow.city.delays);

          tableRow.dynamicSpoc.forEach((value, index) => {
            dynamicSpocValues[index] =
              parseInt(dynamicSpocValues[index]) + parseInt(value);
          });
        } else if (tableRow.type === `total`) {
          tableRow.city.allocated = cityAllocated;
          tableRow.city.delays = cityDelays;
          tableRow.dynamicSpoc = dynamicSpocValues;
        }
      });
      // });
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    getSpocs() {
      // let url = `${constants.SERVER_API}getSpocs`;
      let url = ``;

      if (
        localStorage.getItem("role") === "cm1" ||
        localStorage.getItem("role") === "ac1" ||
        localStorage.getItem("role") === "cm4"
      ) {
        url = `${constants.SERVER_API}getSpocs`;
      } else if (localStorage.getItem("role") === "exed") {
        url = `${constants.SERVER_API}getExed`;
      }
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.loggedin = response.data.logged_in_user_id;
          this.spoc_names = response.data.spocs;
          // this.extractSpocByCity(response.data.spocs);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getrole(providedrole) {
      if (localStorage.getItem("role") === providedrole) {
        return true;
      } else {
        return false;
      }
    },
    extractSpocByCity(response) {
      var unique = response
        .map((name) => {
          return {
            count: 1,
            name: name.city,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      this.cities = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          this.cities.push(key);
        }
      }
      this.cities = this.sortArrayAlphabetically(this.cities);
      if (localStorage.getItem("role") === "cm4") {
        this.selected_city = this.cities[0];
      } else if (
        localStorage.getItem("role") === "cm1" ||
        localStorage.getItem("role") === "ac1" ||
        localStorage.getItem("role") === "exed"
      ) {
        this.spoc_names.forEach((spoc) => {
          if (spoc.id === this.loggedin) {
            console.log("assigning the city");
            this.selected_city = spoc.city;
          }
        });
      }
      this.updateValues();
    },
  },
  components: {
    DateRangePicker,
    Datepicker,
    VueApexCharts,
    "v-select": vSelect,
  },
};
</script>
<style>
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
}
</style>
